import request from '@/http/request.js'

// 0.判断用户是否已存在(/system/userexist)
export function UserExist(data) {
    return request('/api/system/userexist', data)
}

// 2.账户登录(/login/login)
export function Login(data) {
    return request('/api/login/login', data)
}

// 新账户登录-前面sign(/login/loginv2)
export function LoginV2(data) {
    return request('/api/login/loginv2', data)
}

// 3.账户登出(/user/logout)
export function Logout(data) {
    return request('/api/user/logout', data)
}

// 4.获取用户信息(/user/info)
export function UserInfo(data) {
    return request('/api/user/info', data)
}

// 5.修改昵称(/user/editnickname)
export function EditNickname(data) {
    return request('/api/user/editnickname', data)
}

// 17.质押兑换(/user/exchangeasset)
export function ExchangeAsset(data) {
    return request('/api/user/exchangeasset', data)
}

// 17-1.质押兑换确认(/user/exchangeasSetStatus)
export function ExchangeasSetStatus(data) {
    return request('/api/user/exchangeasSetStatus', data)
}

// 20.资产-挖矿账户(/user/miningaccount)
export function MiningAccount(data) {
    return request('/api/user/miningaccount', data)
}

// 21.资产-挖矿账户-资金账单(/user/mininglog)
export function MiningLog(data) {
    return request('/api/user/mininglog', data)
}

// 22.资产-全网账户(/user/networkaccount)
export function NetworkAccount(data) {
    return request('/api/user/networkaccount', data)
}

// 23.资产-全网账户-资金账单(/user/networklog)
export function NetworkLog(data) {
    return request('/api/user/networklog', data)
}

// 9.质押信息(/user/position)
export function Position(data) {
    return request('/api/user/position', data)
}

// 10.兑换记录(/user/exchangelog)
export function ExchangeLog(data) {
    return request('/api/user/exchangelog', data)
}

// 10.质押记录-可提取数量(/user/exchangect)
export function ExchangeCt(data) {
    return request('/api/user/exchangect', data)
}

// 11.邀请信息(/user/invitinfo)
export function InvitInfo(data) {
    return request('/api/user/invitinfo', data)
}

// 12.邀请信息-推广记录(/user/invitlist)
export function InvitList(data) {
    return request('/api/user/invitlistv2', data)
}

// 12.邀请信息-推广记录(/user/ratelog)
export function RateList(data) {
    return request('/api/user/ratelog', data)
}

// 13.获取海报(/user/getposter)
export function GetPoster(data) {
    return request('/api/user/getposter', data)
}

// 24.我的-节点管理-节点信息(/user/nodeInfo)
export function NodeInfo(data) {
    return request('/api/user/nodeInfo', data)
}

// 
export function NodeInfoChild(data) {
    return request('/api/user/nodeInfoChild', data)
}

// 
export function GetNodeChilduserdetail(data) {
    return request('/api/user/getnodeChilduserdetail', data)
}

// 25.我的-节点管理-节点详情(/user/nodeDetail)
export function nodeDetail(data) {
    return request('/api/user/nodeDetail', data)
}

// 26.我的-节点管理-节点添加(/user/nodeAdd)
export function NodeAdd(data) {
    return request('/api/user/nodeAdd', data)
}

// 27.我的-节点管理-节点修改(/user/nodeEdit)
export function NodeEdit(data) {
    return request('/api/user/nodeEdit', data)
}

// 28.我的-节点管理-节点删除(/user/nodeDel)
export function NodeDel(data) {
    return request('/api/user/nodeDel', data)
}

// 29.我的-节点管理-根据名称获取用户信息(/user/getUserByName)
export function GetUserByName(data) {
    return request('/api/user/getUserByName', data)
}

// 30.质押记录-状态(/user/exchangestatus)
export function ExchangeStatus(data) {
    return request('/api/user/exchangestatus', data)
}

// 31.添加上级(/user/addup)
export function addUpApi(data) {
    return request('/api/user/addup', data)
}

// 32.设置eth(/user/seteth)
export function setEth(data) {
    return request('/api/user/seteth', data)
}

// 33.团队,矿池,ab,天使提取(/user/extractlog)
export function ExtractLog(data) {
    return request('/api/user/extractlog', data)
}

// /api/user/getabaddrarrangel   获取天使轮信息
export function getAbAddrArrAngel(data) {
    return request('/api/user/getabaddrarrangel', data)
}

// /api/user/setabaddrarrangelStatus  设置天使轮信息  参数 txid
export function setAbAddrArrAngelStatus(data) {
    return request('/api/user/setabaddrarrangelStatus', data)
}

// /api/user/getabaddrarrab   获取AB轮信息
export function getAbAddrArrAb(data) {
    return request('/api/user/getabaddrarrab', data)
}

// /api/user/setabaddrarrabStatus  设置AB轮信息  参数 txid
export function setAbAddrArrAbStatus(data) {
    return request('/api/user/setabaddrarrabStatus', data)
}

// /api/user/getreferendum 获取空投数据
export function getReferendum(data) {
    return request('/api/user/getreferendum', data)
}

// 获取节点详情
export function getNodeUserDetail(data) {
    return request('/api/user/getnodeuserdetail', data)
}

// 35.每日统计(/user/statistics)
export function statisticsApi(data) {
    return request('/api/user/statistics', data)
}

// 36.每日统计-挖矿记录(/user/statisticsmining)
export function statisticsMiningApi(data) {
    return request('/api/user/statisticsmining', data)
}

// 37.每日统计-节点记录(/user/statisticsnode)
export function statisticsNodeApi(data) {
    return request('/api/user/statisticsnode', data)
}

// 38.获取加速率(/user/getrate)
export function GetRateApi(data) {
    return request('/api/user/getrate', data)
}

// 待修正列表(/user/miningerror)
export function MiningErrorApi(data) {
    return request('/api/user/miningerror', data)
}

// 待修正列表(/user/miningerror)
export function orderInfo(data) {
    return request('/api/user/orderInfo', data)
}

// 弹窗公告
export function announceInfo(data) {
    return request('/api/index/top', data)
}

export function newsList(data) {
    return request('/api/index/news', data)
}

export function newsDetail(data) {
    return request('/api/index/newsdetail', data)
}

// 奖金账户
export function rewardInfo(data) {
    return request('/api/user/bonus', data)
}

// 奖金明细
export function RewardLog(data) {
    return request('/api/user/bonuslog', data)
}

// 我的页面
export function myInfo(data) {
    return request('/api/user/my', data)
}

// VIP等级列表
export function vipList(data) {
    return request('/api/user/teamlvcount', data)
}

// 邀请页面
export function myInvite(data) {
    return request('/api/user/team', data)
}

// 提现配置
export function withdrawInfo(data) {
    return request('/api/user/withdrawconf', data)
}

// 提现
export function goWithdraw(data) {
    return request('/api/user/withdraw', data)
}
// 51.邀请信息-推广记录-下级(/user/invitlisttree)
export function TnvitListTree(data) {
    return request('/api/user/invitlisttree', data)
}
// 52.邀请信息-下级信息-下级(/user/treeinfo)
export function TreeInfo(data) {
    return request('/api/user/treeinfo', data)
}
// 53.我的-节点管理-节点信息-子节点(/user/nodetree)
export function NodeTree(data) {
    return request('/api/user/nodetree', data)
}
// 54.我的nft(/nft/own)
export function myNft(data) {
    return request('/api/nft/own', data)
}
// 55.可兑换nft(/nft/list)
export function nftList(data) {
    return request('/api/nft/list', data)
}
// 56.兑换nft后上传日志(/nft/log)
export function nftLog(data) {
    return request('/api/nft/log', data)
}
// 57.判断是否可以合成(/nft / compound)
export function nftCompound(data) {
    return request('/api/nft/compound', data)
}

// /api/mb/balance   获取转账的余额和地址  入参 无   返回  ["balance", "address"]
export function mbBalanceApi(data) {
    return request('/api/mb/balance', data)
}

// /api/mb/log  用户转账后回调  入参 hash  返回 []
export function mbLogApi(data) {
    return request('/api/mb/log', data)
}
