module.exports = {
	// {{$t('common.balance')}}
	common: {
		loading: '加載中',
		balance: '余額',
		name: '名稱',
		save: '保存',
		OK: '確定',
		CANCEL: '取消',
		day: '天',
		hour: '小時',
		minute: '分鐘',
		seconds: '秒',
		link: '鏈接錢包',
		nodata: '暫無數據',
		all: '全部',
		download: '下載',
		copy: '復製',
		copySuccess: '復製成功',
		copyFail: '該瀏覽器不支持自動復製,請手動復製',
		buy: '購買 TOKENS',
		daily: '每天',
		myPledge: '我的質押',
		toPledge: '去質押',
		angle: '天使輪',
		ab: 'AB輪',
		team: '團隊成員',
		toWrite: '去填寫',
		edit: '修改',
		del: '刪除',
		detail: '查看',
		toMigrate: '遷移'
	},
	tab: {
		home: '首頁',
		asset: '資產',
		mine: '我的',
	},
	toast: {
		name: '請輸入2-12位昵稱',
		icode: '請填寫邀請碼（選填）',
		linkWallet: '鏈接錢包中',
		getBalance: '獲取余額中',
		dealFail: '請求失敗，請刷新重試',
		editSuccess: '修改成功',
		usetrc: '使用以太坊錢包連接',
		complete: '請填寫完整',
		submitSuc: '提交成功',
		extract: '提取發起成功，請耐心等待',
		limit: '檢查額度中，請等待...',
		limit_2: '提升額度中，請等待...',
		pledgeSuccess: '已成功發起請求，請耐心等待網酪確認，請在幾分鐘後查看',
		submit: '提交中',
		contractResult: '該交易在確認中，請稍後刷新重試',
		breakContract: '贖回發起成功，請耐心等待',
		withdraw: '提取發起成功，請耐心等待',
		del: '刪除成功',
		noBalance: '余額為0，無法提取',
		addUp: '请填写推荐人邀请码',
		eth: '輸入42位MATIC地址',
		lowBalance: '請先確認有充足的余額',
	},
	request: {
		paramError: '參數錯誤',
		dataError: '日期錯誤',
		signError: '驗證sign失敗',
		nodata: '無此數據',
		noNetwork: '斷網',
	},
	wallet: {
		walletAddr: '錢包地址',
		chooseW: '選擇鏈接錢包',
		editW: '修改錢包名稱',
		asset: '資產',
		walletAccount: '錢包帳護',
		netwoekAccount: '全網帳護',
		miningAccount: '挖礦帳護',
		assetDesc: '資產明細',
	},
	login: {
		linked: '已鏈接錢包',
		icode: '邀請碼',
		welcome: '歡迎進入',
		notice: '溫馨提醒',
		notice_1: '賬號即將創建成功，您的賬號未填寫邀請人。',
		notice_2: '如果您的賬號成功邀請他人成為團隊成員後將無法綁定邀請人，若未邀請他人加入團隊，你可以到我的團隊中再次綁定，確定要創建賬號嗎？',
		balance_1: '可質押',
		balance_2: '賬戶資產',
		addr: '映射地址',
	},
	home: {
		pledge: '質押',
		vote: '參與投票',
		partition: '每天挖礦瓜分',
		network: '全網質押總量',
		yesterday: '昨日發放挖礦獎勵',
		destroy: '上周銷毀數量',
		assets: '總資產',
		daily: '預計每日挖礦收益',
		list: '質押產品列表',
		year: '參考年化',
		date: '期限',
		tip: '質押10000預計每日收益',
		join: '參與',
		list_tab_1: '流動性質押',
		list_tab_2: '單幣質押',
		news: '新聞動態',
		detail: '查看詳情'
	},
	exchange: {
		into: '轉入',
		step_2: '選擇數量、周期、節點',
		step_3: '質押完成',
		pledgeNum: '質押數量',
		pledged: '可質押數量',
		minPledge: '輸入質押數量(最小',
		cycle: '質押周期',
		note: '投票節點',
		choosenote: '選擇節點',
		pledgeNum_1: '本次質押數量',
		speed: '加速權益將提升至',
		pledge: '質押',
		record: '我的質押記錄',
		rule_1: '質押規則',
		rule_2: '如何加速',
		rule_3: '收益計算',
		confirm: '確認質押訂單',
		note_2: '節點',
		speed_2: '加速增益',
		btn_1: '授權並質押',
		title_1: '提升合約轉賬額度',
		title_2: '當前合約轉賬額度不足，請提升合約轉賬額度',
		btn_2: '授權並提升',
		minPledge_2: '質押數量最小',
		slide: '滑點保護',
		slide_tips: '請輸入自定義滑點保護數值',
		slide_notice: '質押失敗可提高滑點保護數值重試(最高15%)',

		rate_1: '當前',
		rate_2: '天周期質押量',
		rate_3: '加速權益',
		rate_4: '再質押',
		rate_5: '可提升至',
	},
	record: {
		pledgeing: '質押中',
		daily: '預計每日挖礦收益',
		total: '累計獲得挖礦收益',
		status_1: '質押中',
		status_2: '已到期',
		status_3: '已贖回',
		status_4: '未到時間',
		pledgeID: '質押ID',
		contractID: '合同ID',
		pledgeNum: '質押數',
		redeem: '贖回',
		withdraw: '提取',
		extract: '全部提取',
		notice: '现在赎回将扣除20%本金，剩余的资金将在24小时后才可提取。确定赎回吗？',
		endTime: '到期時間',
		rate: '實時加速',
		reDaily: '預計每日',
		reward: '挖礦收益',
		source: '已解壓',
		reAvg: '日均收益',
		reTime: '贖回時間',
	},
	success: {
		title: '質押交易已提交',
		sub_title: '合約執行可能因為區塊網絡原因執行較慢，如果交易失敗，則會返回質押的資產，請請耐心等待合約完成交易。',
		orderId: '訂單號',
		time: '時間',
		pledgeNum: '質押數量',
		pledgeCycle: '質押周期',
		quote: '節點',
		rate: '加速增益',
		back: '返回首頁',
		hash: '交易Haxh',
		link: '查看鏈上詳情',
		tips: '最终质押数量以合约为准',
	},
	invite: {
		rule: '了解增速規則',
		teamNum: '團隊人數',
		teamRate: '團隊加速',
		teamPledge: '團隊質押量',
		myTeam: '我的團隊',
		record: '加速記錄',
		speed: '速度加成',
		pledgeTime: '質押時間',
		pledgeNum: '質押量',
		pledgeIng: '質押中',
		myLink: '我的推廣鏈接',
		joinT: '加入時間',
		addUp: '綁定推薦人',
		up: '推薦人',

		reward: '賞金金額',
		towith: '去提現',
		mySpeed: '我的加速度',
		allTeam: '累計團隊賞金',
		teamReward: '團隊賞金比例',
		allTeamPledge: '累計團隊質押',
		inviteOthers: '邀請他人加入',
		knowRule: '瞭解獎勵規則',
		shareLink: '分享連結',
		otherLogin: '他人使用錢包登入並綁定',
		matePledge: '團隊成員質押',
		inviteLink: '邀請連結',
		code: '邀請碼',
		grade: '等級'
	},
	asset: {
		liquid: '流動性賬戶',
		mining: '單幣性賬戶',
		network: '全網賬戶',
		reward: '賞金帳戶',
		mExtract: '可提取挖礦獎勵',
		earn: '昨日收益',
		pledge: '質押中',
		extracted: '可提取質押數',
		btn_1: '提取獎勵至錢包',
		asset: '總資產',
		angle: '天使輪可提取',
		ab: 'AB輪可提取',
		team: '團隊账户可提取',
		btn_2: '提取至錢包',
		bill: '資金賬單',
		remain: '賞金餘額',
		towith: '申請提現',
		yesreward: '昨日賞金',
		rewardetail: '賞金明細',
		surplus: '剩余資產',
		initial: '初始資產',

		tab_1: '全部',
		tab_2: '質押',
		tab_3: '挖礦獎勵',
		tab_4: '節點獎勵',
		tab_5: '提取',

		daily: '每日統計',
		total: '累計總收益',
		withdraw_1: '可提取挖礦/節點獎勵',
		withdraw_2: '可提取質押贖回/到期',

		error: '待修正收益',
		error_title: '數據修正說明',
		error_subtitle: '合約預言機異常導致挖礦合約結算有誤，誤差值將會在之後的結算進行修正。誤差數值如下',
		error_item1: '時間',
		error_item2: '已發放',
		error_item3: '待修正',
	},
	notice: {
		text_1: '可質押數為當前去中心化錢包',
		text_1_2: '的余額',
		text_2: '挖礦賬戶：是指質押挖礦的合約賬戶資金情況',
		text_3: '可提取挖礦獎勵：挖礦合約和質押合約中可提取到錢包的代幣數量',
		text_4: '質押中：質押合約中質押中的數量',
		text_5: '可提取質押數：質押合約可提取到錢包的代幣數量',
		text_6: '全網賬戶：是指投資每日釋放的合約的賬戶情況',
		text_7: '可提取：是指釋放合約中可提取至錢包的代幣數量',
		text_8: '鎖定中：是指釋放合約中鎖定中的代幣數量',
		text_9: '累計釋放：是指已經釋放的代幣數量',
		text_10: '可提取包含',
		text_11: '1. 未到期贖回的資產(提前贖回將扣除20%質押數量)；',
		text_12: '2. 到期可提取的資產；',
		text_13: '團隊賬戶壹年後釋放',
		text_14: '即將開放',
	},
	note: {
		addChild: '新增子節點',
		editChild: '修改子節點',
		account: '賬戶名稱',
		note: '節點名稱',
		address: '錢包地址',
		prorate: '分紅比例',
		pla_1: '輸入子節點賬戶名稱',
		pla_2: '請先輸入賬戶名稱',
		pla_3: '輸入節點收益占比',
		pla_4: '輸入節點名稱',
		allot: '可分配比例',
		cycle: '發放周期',
		day: '按天',
		month: '按月',
		status: '發放狀態',
		normal: '正常',
		pause: '暫停',
		mode: '子節點分配模式',
		custom: '自定義',
		percent: '按業績占比',
		submitAdd: '確定添加',
		submitEdit: '確定修改',

		all: '節點總覽',
		nowPledge: '當前質押量',
		nowPledge2: '子節點業績',
		monthAdd: '本月新增質押',
		monthAdd2: '本月新增團隊業績',
		earnRatio: '收益比例',
		earnRatio2: '上級分配分紅',
		issueCycle: '發放周期',
		accumulated: '累計收益',
		issued: '待發放收益',
		myInfo: '我的信息',
		team: '團隊業績',
		ratio: '分紅比例',
		child: '子節點',
		noChild: '暫無子節點',
		add: '本月新增',
		superior: '上級分配分紅',
		type_1: '按比例',
		type_2: '按業績',
		quote_all: '累積質押量',
		quote_team: '團隊質押業績',
		tree1: '直推人數',
		quote_reward: '累積質押獎勵',
		team_reward: '累積推廣獎勵',

		pledgeDetail: '質押明細',
		allPledge: '累計質押',
		allPledgeReward: '累计挖矿所得',

		core: '核心',
		generation: '代',
	},
	withdraw: {
		mExtract: '挖礦賬戶提取',
		nExtract: '全網賬戶提取',
		extract: '提取至當前錢包地址',
		extracted: '可提取',
		balance: '余額',
		fee: '費',
		consume: '消耗能量或者',
		wallet: '錢包',
		notice: '余額不足可能導致提取不成功',
		btn_1: '授權並提取至錢包',
		toast_1: '區塊網絡擁堵',

		step_1: '提交申請',
		step_2: '平臺稽核',
		step_3: '通過後到賬',
		price: '提現金額',
		available: '可提現賞金',
		amountPlaceholder: '輸入提現數量',
		min: '最小',
		addr: '錢包地址',
		input: '輸入',
		amount: '提現數量',
		hfee: '手續費',
		income: '即將收到',
		admit: '申請提現',
		admitted: '提交成功',
	},
	mine: {
		name: '您的名稱',
		team: '我的團隊',
		node: '節點管理',
		teamAccount: '團隊賬戶',
		introduce: '項目介紹',
		protocol: '用戶協議及隱私',
		language: '語言切換',
		contact: '聯系我們',
		teamfast: '團隊加速',
		teamrewardrate: '團隊賞金比率',
		mybet: '我的質押',
		myfloatbet: '我的流動性質押',
		mysinglebet: '我的單幣質押'
	},
	daily: {
		all: '累計總收益',
		yesterday: '昨日收益',
		yes_mining: '昨日挖礦收益',
		yes_node: '昨日節點收益',
		release_mining: '待交割挖礦收益',
		release_node: '待交割節點收益',
		all_mining: '累計挖礦收益',
		all_node: '累計節點收益',
		mining_reward: '挖礦收益',
		node_reward: '節點收益',
		date: '日期',
		status: '狀態',
		pledge: '當日質押量',
		confirm: '完成',
		end: '請選擇結束時間',
	},
	nft: {
		mynft: '我的NFT',
		compose: '合成',
		deal: 'NFT交易',
		price: '價格',
		sort: '篩選',
		inputid: '蒐索編號或名稱',
		developing: '監修中',
		all: '全部',
		sold: '在售',
		free: '閒置',
		lv1: '青銅',
		lv2: '白銀',
		lv3: '黃金',
		lv4: '紫金',
		lv5: '传奇',
		a1: '金',
		a2: '木',
		a3: '水',
		a4: '火',
		a5: '土',
		composeNft: '合成NFT',
		thing: '物品',
		pay: '支付',
		fee: '合成費用',
		com_confirm: '確認合成',
		lvtitle: '等級篩選',
		attrititle: '内容篩選',
		tosold: '出售',
		detail: '詳情',
		address: '合約地址',
		token: '代幣',
		network: '網絡',
		rule: '代幣標準',
		allowing: 'NFT合成授權中',
		allow: '授權',
		composeDeal: '合成交易',
		allow_notice: '授權程式為自動進行，如遇行程緩慢，請至錢包NFT轉帳頁面加速。 一旦授權成功功能後會自動提交合成交易，請耐心等待。 授權/合成交易超時或失敗則需要你重新提交合成。',
		admited: '合成操作已提交上鏈',
		admit_notice: '合約執行可能因為區塊網絡原因執行較慢，如果交易失敗，則會返回NFT，請請耐心等待合約完成交易。',
		hash: '交易Hash',
		toLine: '查看鏈上詳情',
		backHome: '返回首頁'
	}
}