module.exports = {
  // {{$t('common.balance')}}
  common: {
    loading: 'Loading',
    balance: 'Balance',
    name: 'Name',
    save: 'Save',
    OK: 'OK',
    CANCEL: 'Cancel',
    day: 'Day',
    hour: 'Hour',
    minute: 'Minute',
    seconds: 'Seconds',
    link: 'Link wallet',
    nodata: 'No data',
    all: 'All',
    download: 'Download',
    copy: 'Copy',
    copySuccess: 'Copied',
    copyFail: 'This browser does not support automatic copying, please copy manually',
    buy: 'Buy TOKENS',
    daily: 'Every day',
    myPledge: 'My Staking',
		toPledge: 'Depledge',
    angle: 'Angel Round',
    ab: 'A/B round',
    team: 'Team',
		toWrite: 'Fill now',
		edit: 'Edit',
		del: 'Delete',
    detail: 'Detail',
		toMigrate: 'Migrate'
  },
  tab: {
    home: 'Home',
    asset: 'Assets',
    mine: 'Account',
    },
  toast: {
    name: 'Enter 2-12 nickname',
    icode: 'Fill in the invitation code (optional)',
    linkWallet: 'Link Wallet',
    getBalance: 'Get the balance',
    dealFail: 'The request failed, please refresh and try again',
    editSuccess: 'Modification succeeded',
    usetrc: 'Connect via MATIC wallet',
    complete: 'Please fill in completely',
    submitSuc: 'Submit successfully',
    extract: 'The transfer was initiated successfully, please be patient',
    limit: 'Checking the quota, please wait...',
    limit_2: 'Increasing the quota, please wait...',
    pledgeSuccess: 'The request has been successfully initiated, please wait patiently for the confirmation of Net Cheese, please check in a few minutes',
    submit: 'Submitting',
    contractResult: 'The transaction is being confirmed, please refresh and try again later',
    breakContract: 'The redemption was initiated successfully, please wait patiently',
    withdraw: 'The withdrawal was initiated successfully, please wait patiently',
    del: 'Delete successful',
    noBalance: 'The balance is 0 and cannot be withdrawn',
		addUp: 'Please fill in the invitation code',
		eth: 'Enter a 42-bit MATIC address',
		lowBalance: 'Please make sure there is sufficient balance first',
  },
  request: {
    paramError: 'Parameter error',
    dataError: 'Date error',
    signError: 'Verification failed',
    nodata: 'No such data',
    noNetwork: 'Disconnected network',
  },
  wallet: {
    walletAddr: 'Wallet address',
    chooseW: 'Choose the wallet',
    editW: 'Modify wallet name',
    asset: 'Assets',
    walletAccount: 'Wallet account',
    netwoekAccount: 'Token account',
    miningAccount: 'Mining Account',
    assetDesc: 'Details',
  },
  login: {
    linked: 'Linked wallet',
    icode: 'Invitation code',
    welcome: 'Welcome to',
		notice: 'Warm Reminder',
		notice_1: 'The account is about to be created successfully. Your account has not filled in the inviter.',
		notice_2: 'If your account successfully refers others, you will not be able to bind the inviter. If you haven‘t referred others, you can bind to my team again. Are you sure to create an account now?',
    balance_1: 'May pledge',
		balance_2: 'Account asset',
		addr: ' Mapping Address',
  },
  home: {
    pledge: 'Staking mining',
    vote: '',
    partition: 'Participate in to divide up rewards',
    network: 'The total amount of staking',
    yesterday: 'The mining rewards yesterday',
    destroy: 'Number of destroyed last week',
    assets: 'Total assets',
    daily: 'Estimated daily mining income',
    list: 'Hot Staking Mining',
    year: 'Reference APY',
    date: 'Term',
    tip: 'Staking 10000 expected daily income',
    join: 'Join',
		list_tab_1: 'LP Mining',
		list_tab_2: 'Single Mining',
    news: 'News',
    detail: 'detail'
  },
  exchange: {
    into: 'Transfer in ',
    step_2: 'Select quantity, period, node ',
    step_3: 'Staking completed ',
    pledgeNum: 'Quantity',
    pledged: 'Available',
    minPledge: 'Enter the amount (min',
    cycle: 'Term',
    note: 'Choose node',
		choosenote: 'Select node',
		pledgeNum_1: 'The amount pledged this time ',
    speed: 'The accelerated interest will rise to ',
    pledge: 'Submit',
    record: 'My staking records',
    rule_1: 'Staking rule',
    rule_2: 'How to speed up',
    rule_3: 'Revenue calculation',
    confirm: 'Confirm staking order',
    note_2: 'Node',
    speed_2: 'Speed up',
    btn_1: 'Authorize and stake',
    title_1: 'Increase contract transfer limit',
    title_2: 'The current contract transfer quota is insufficient, please increase the contract transfer quota',
    btn_2: 'Authorized',
    minPledge_2: 'Minimum staking amount',
		slide: 'Slippage Protection',
		slide_tips: 'Please enter a custom slippage protection value',
		slide_notice: 'Increase slippage protection value for retrying failed pledge (up to 15%)',

		rate_1: 'At present ',
		rate_2: ' daily amount pledged ',
		rate_3: ' accelerated interest ',
		rate_4: 'repledge ',
		rate_5: 'can be raised to ',
  },
  record: {
    pledgeing: 'Staking',
    daily: 'Estimated daily mining income',
    total: 'Total mining rewards',
    status_1: 'Staking',
    status_2: 'Expired',
    status_3: 'Redeemed',
    status_4: 'Notyet time',
    pledgeID: 'Staking ID',
		contractID: 'Contract ID',
    pledgeNum: 'Amount',
    redeem: 'Redeem',
		withdraw: 'Withdraw',
    extract: 'Collect',
		notice: 'Redemption now will deduct 20% of the principal, the remaining funds will be withdrawn after 24 hours to confirm redemption?',
		endTime: 'Expiration',
		rate: 'Acceleration',
		reDaily: 'Estimated daily',
		reward: 'Mining income',
		source: 'Decompressed',
		reAvg: 'Average daily',
		reTime: 'Redemption',
  },
  success: {
		title: 'A pledge transaction has been filed',
		sub_title: 'The execution of the contract may be slow due to block network reasons, if the transaction fails, the pledged asset will be returned, please wait patiently for the contract to complete the transaction.',
    orderId: 'Order ID',
    time: 'Time',
    pledgeNum: 'Amount',
    pledgeCycle: 'Term',
    quote: 'Node',
    rate: 'Speed up',
    back: 'Homepage',
		hash: 'Trade Haxh',
		link: 'View on-chain details',
  },
  invite: {
    rule: 'Know the speed-up rules',
    teamNum: 'Members',
    teamRate: 'Team Speed',
		teamPledge: 'Team Pledgeing',
    myTeam: 'My Team',
    record: 'Record',
    speed: 'Speed bonus',
    pledgeTime: 'Staking term',
    pledgeNum: 'Amount',
		pledgeIng: 'Pledgeing',
    myLink: 'Referral link',
    joinT: 'Join time',
		addUp: 'Bind referees',
		up: 'Referrer',

    reward: 'Bonus amount',
		towith: 'Withdrawal',
		mySpeed: 'My acceleration',
		allTeam: 'Accumulated team bonus',
		teamReward: 'Team bonus ratio',
		allTeamPledge: 'Accumulated team pledge',
		inviteOthers: 'Invite others to join',
		knowRule: 'Reward rules',
    shareLink: 'Share Link',
		otherLogin: 'Someone else logs in and bind',
		matePledge: 'Team member pledge',
		inviteLink: 'Invite Link',
		code: 'Invite Code',
    grade: 'Level'
  },
  asset: {
		liquid: 'LP account',
		mining: 'Single account',
    network: 'Token account',
    reward: 'Reward account',
    mExtract: 'Withdrawable mining rewards',
    earn: "Yesterday's earnings",
    pledge: 'Staking',
    extracted: 'Withdrawable staking amount',
    btn_1: 'Withdraw',
    asset: 'Total Assets',
    angle: 'Angel Round',
    ab: 'AB Round',
    team: 'Team funds',
    btn_2: 'Withdraw',
    bill: 'Funds bill',
    remain: 'Bonus balance',
		towith: 'Withdraw',
		yesreward: "Yesterday's bonus",
		rewardetail: 'Income detail',
		surplus: 'Surplus assets',
    initial: 'Initial assets',

		tab_1: 'All',
    tab_2: 'Pledge',
    tab_3: 'Mining',
    tab_4: 'Node',
    tab_5: 'Extract',

		daily: 'Daily statistics',
		total: 'Cumulative gross income',
		withdraw_1: 'Extractable mining/node rewards',
		withdraw_2: 'Redeemable pledge/maturity',

		error: 'Earnings to be corrected',
		error_title: 'Data correction specification',
		error_subtitle: 'Contract prognosticator anomaly caused mining contract settlement error, error value will be corrected in future settlement. The error values are as follows',
		error_item1: 'Time',
		error_item2: 'Issued',
		error_item3: 'To be corrected',
  },
  notice: {
    text_1: 'The amount that can be staked is your decentralized wallet',
    text_1_2: 'Balance of',
    text_2: 'Mining account: the contract account funds for staking mining',
    text_3: 'Withdrawable mining rewards: the amount of tokens in the mining contract and the staking contract that can be extracted to the wallet',
    text_4: 'Staking: the amount of token in the staking contract',
    text_5: 'Withdrawable staking amount: the amount of tokens that the staking contract can withdraw to the wallet',
    text_6: 'The token account: the account status of the contract released every day',
    text_7: 'Withdrawable: the amount of tokens in the release contract that can be withdrawn to your wallet',
    text_8: 'Locking: releasing the amount of tokens locked in the contract',
    text_9: 'Total release: the amount of tokens that have been released',
    text_10: 'Extractable contains',
    text_11: "1. Assets that haven't been redeemed (20% of the pledged quantity will be deducted for early redemption);",
    text_12: '2. Assets that can be withdrawn at maturity;',
    text_13: 'The team account will be released after one year',
		text_14: 'OPEN SOON',
  },
  note: {
    addChild: 'Add',
    editChild: 'Modify',
    account: 'Account name',
    note: 'Node name',
    address: 'Wallet address',
    prorate: 'Bouns',
    pla_1: 'Enter the child node account name',
    pla_2: 'Enter the account name first',
    pla_3: 'Input node revenue ratio',
    pla_4: 'Enter node name',
    allot: 'Allocation ratio',
    cycle: 'Issue term',
    day: 'By day',
    month: 'By month',
    status: 'Issue status',
    normal: 'Normal',
    pause: 'Pause',
    mode: 'Child node allocation mode',
    custom: 'Custom',
    percent: 'Percentage by performance',
    submitAdd: 'Submit',
    submitEdit: 'Submit',
    
    all: 'Node Overview',
    nowPledge: 'Current staking amount',
		nowPledge2: 'Child node performance',
    monthAdd: 'This month',
		monthAdd2: 'New team performance this month',
    earnRatio: 'Earning ratio',
		earnRatio2: 'Superior distribution',
    issueCycle: 'Issue term',
    accumulated: 'Total income',
    issued: 'To be issued',
    myInfo: 'My information',
    team: 'Team performance',
    ratio: 'Revenue ratio',
    child: 'Child node',
    noChild: 'No child node',
    add: 'This month',
    superior: 'Superior distributes dividends',
    type_1: 'By ratio',
    type_2: 'By performance',
		quote_all: 'Cumulative pledge',
		quote_team: 'Team pledge',
		tree1: 'Direct number',
		quote_reward: 'Cumulative pledge',
		team_reward: 'Cumulative promotional',

		pledgeDetail: 'Pledge detail',
		allPledge: 'Cumulative pledge',
		allPledgeReward: 'Cumulative mining income',

		core: 'Core',
		generation: 'Dynasty',
  },
  withdraw: {
    mExtract: 'Mining account withdraw',
    nExtract: 'Token account withdraw',
    extract: 'Withdraw to',
    extracted: 'Withdarwable',
    balance: 'Balance',
    fee: 'fee',
    consume: 'Consume energy or',
    wallet: 'Wallet',
    notice: 'Insufficient fee may lead to unsuccessful withdrawal',
    btn_1: 'Authorize and withdraw',
		toast_1: 'Block network congestion.',

    step_1: 'Submit application',
		step_2: 'Platform Audit',
		step_3: 'Received after approval',
		price: 'Amount',
		available: 'Available',
		amountPlaceholder: 'Enter withdrawal quantity',
		min: 'min',
		addr: 'Address',
		input: 'Enter',
		amount: 'Amount',
		hfee: 'Fee',
		income: 'Coming',
		admit: 'Submit',
    admitted: 'Submitted successfully',
  },
  mine: {
    name: 'Your name',
    team: 'My team',
    node: 'Node management',
    teamAccount: 'Team Account',
    introduce: 'Project introduction',
    protocol: 'User Agreement and Privacy',
    language: 'Language',
		contact: 'Contact',
    teamfast: 'Team acceleration',
		teamrewardrate: 'Team bonus ratio',
		mybet: 'My pledge',
		myfloatbet: 'Liquidity pledge',
		mysinglebet: 'Single Currency Pledge'
  },
  daily: {
		all: 'Total revenue',
		yesterday: "Yesterday's income",
		yes_mining: "Yesterday's mining income",
		yes_node: "Yesterday's node income",
		release_mining: 'Pending delivery mining income',
		release_node: 'Pending delivery node income',
		all_mining: 'Cumulative mining income',
		all_node: 'Cumulative node income',
		mining_reward: 'Mining income',
		node_reward: 'Node income',
		date: 'Date',
		status: 'Status',
		pledge: 'Amount pledged on the day',
		confirm: 'Complete',
		end: 'Please select an end time',
	},
  nft: {
		mynft: 'My NFT',
		compose: 'Compose',
    deal: 'NFT Market',
		price: 'Price',
		sort: 'Filter',
		inputid: 'Search number or name',
		developing: 'Developing',
		all: 'All',
		sold: 'For Sale',
		free: 'Leave Unused',
    lv1: 'Bronze',
		lv2: 'Silver',
		lv3: 'Gold',
		lv4: 'Violet Gold',
    lv5: 'Legend',
    a1: 'Gold',
		a2: 'Wood',
		a3: 'Water',
		a4: 'Fire',
		a5: 'Soil',
    composeNft: 'Compose NFT',
		thing: 'Article',
		pay: 'Pay',
		fee: 'Cost',
		com_confirm: 'Compose',
    lvtitle: 'Level filtering',
		attrititle: 'Attribute filtering',
    tosold: 'Sold',
		detail: 'Detail',
		address: 'Contract Address',
		token: 'Token',
		network: 'Network',
		rule: 'Standards',
    allowing: 'NFT synthesis authorization in progress',
		allow: 'authorization',
    composeDeal: 'Synthetic transactions',
		allow_notice: 'The authorization process is automatic. If the itinerary is slow, please go to the wallet NFT transfer page to speed up. Once the authorization is successful, the composite transaction will be automatically submitted. Please be patient. If the authorization/synthesis transaction times out or fails, you will need to resubmit the synthesis.',
		admited: 'The synthesis operation has been submitted for chain connection',
		admit_notice: 'The contract execution may be slow due to memory block network issues. If the transaction fails, an NFT will be returned. Please be patient and wait for the contract to complete the transaction.',
		hash: 'Hash',
		toLine: 'View on chain details',
		backHome: 'Back to Home'
	}
}